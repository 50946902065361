<template>
    <div class="inner-container">
        <div class="table-container">
            <b-table
                :fields="tableFields"
                :items="decodeData.goodsData"
                responsive="true"
                bordered
                head-variant="light"
                sticky-header="true"
                no-border-collapse
            >   
                <template #head(more)>
                    <div class="text-center">
                        <i 
                            :title="getCommonText('del_selected')"
                            class="icon icon-close table-all-remove"
                            @click="deleteItem(`${areAllGoodsSelected ? getDecText('del_all_recs') : getDecText('del_selected_recs') }`)"/>
                    </div>
                </template>
                <template #top-row="data">
                    <td></td>
                    <td class="td-numbering table-success text-center">1</td>
                    <td class="td-numbering table-danger text-center">2</td>
                    <td class="td-numbering table-info text-center">3</td>
                    <td class="td-numbering table-primary text-center">4</td>
                    <td class="td-numbering table-warning text-center">5</td>
                    <td class="td-numbering table-success text-center">6</td>
                    <td class="td-numbering table-danger text-center">7</td>

                    <td class="td-numbering text-center"></td>
                </template>
                <template #head(action)>
                    <div class="text-center">
                        <b-form-checkbox 
                            v-model="areAllGoodsSelected" 
                            @change="e => setIsAllDelete(e)"
                            :value="true"
                            :unchecked-value="false" />
                    </div>
                </template>
                
                <template #cell(action)="data">
                    <b-form-checkbox 
                        v-model="data.item.isItemToDelete"
                        @input="e => { if (!e) areAllGoodsSelected = false; }"
                        :value="true"
                        :unchecked-value="false" />
                </template>
                <template #cell(name_object)="data">
                    <div class="bp-category-name">
                        <div class="text-left">{{ data.item[categoryFieldNames[lng]] }}</div>
                        <div>{{ data.item['name_' + lng] }} </div>
                        <i 
                            v-if="variantAttribute"
                            class="icon icon-pencil-edit thin-blue ml-3 pointer icon-18" 
                            @click="onEditCatName(data.item)"
                        ></i>
                    </div>
                </template>

                <template #cell(rate)="data">
                    <div class="text-right">{{ $n(data.value) }}</div>
                </template>
                <template #cell(tariff)="data">
                    <b-form-input v-if="variantAttribute"
                                  class="text-right"
                                  :disabled="!(data.item.name_object || data.item.name_object_kk)"
                                  :value="data.item.tariff"
                                  @change="v => {data.item.tariff = v; onDataChanged(data.item, 'tariff')}"
                                  @keyup.enter.exact="keyup13"
                                  @keypress="keyPress($event, '^\\d*\\.?\\d{0,9}$')"
                                  @blur="$emit('inputFixedVldtn', data.item, 'tariff', data.item.tariff, 2)">
                    </b-form-input>
                    <div v-else>{{ $n(data.value) }}</div>
                    <p class="goods-form-val-msg" v-if="variantAttribute && !data.item.tariff && data.item.changed">{{ getCommonText("positive") }}</p>
                </template>
                <template #cell(rate_norm)="data">
                    <div class="text-right">{{ $n(data.value) }}</div>
                </template>
                <template #cell(power)="data">
                    <b-form-input v-if="variantAttribute"
                                  class="text-right"
                                  :disabled="!(data.item.name_object || data.item.name_object_kk)"
                                  :value="data.item.power"
                                  @change="v => {data.item.power = v; onDataChanged(data.item, 'power')}"
                                  @keyup.enter.exact="keyup13"
                                  @keypress="keyPress($event, '^\\d*\\.?\\d{0,9}$')"
                                  @blur="$emit('inputFixedVldtn', data.item, 'power', data.item.power, 2)">
                    </b-form-input>
                    <div v-else>{{ data.value }}</div>
                    <p class="goods-form-val-msg" v-if="variantAttribute && !data.item.power && data.item.changed">{{ getCommonText("positive") }}</p>
                </template>
                <template #cell(total)="data">
                    <div class="text-right">{{ $n(data.value) }}</div>
                </template>
                <template #cell(files)="data">
                    <span
                        class="blue pointer underline"
                        @click="() => { if (data.item.name_object || data.item.name_object_kk) onFilesClick(data.item) }"
                    >({{data.item.files}})</span>
                </template>
                 <template #cell(more)="data">
                    <i class="icon icon-clear table-remove" v-if="variantAttribute" @click="deleteItem(`${getDecText('del_curr_rec')}`, data.item, data.index)"></i>
                </template>
                <template #bottom-row="data">
                    <td class="text-left" colspan="2">{{ getCommonText("total") }}</td>
                    <td class="text-right">{{ $n(avgRate) }}</td>
                    <td class="text-right">{{ $n(totalTarif) }}</td>
                    <td class="text-right">{{ $n(totalRateNorm) }}</td>
                    <td class="text-right">{{ $n(sums.sumPower) }}</td>
                    <td class="text-right">{{ $n(sums.sumTotal) }}</td>
                    <td colspan="2"></td>
                </template>
            </b-table>

            <modal-files-management-nodecode
                ref="modalFilesManagement"
                :variant-attribute="variantAttribute"
                @triggDownloadFile="triggDownloadFile"
                @triggDownloadAllFiles="triggDownloadAllFiles"
                @fileUpload="fileUpload"
                @toggleIsAdd="toggleIsAdd($event)"
            />


            
        </div>
    </div>
</template>

<script>
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import ModalFilesManagementNodecode from './modal-files-management-nodecode.vue'
import BpI18nHandlerMixin from '../mixins/bp-i18n-handler-mixin';

// ##########################################################################

// для форм 01-151, 02-151, 03-151

// ##########################################################################


export default {
    name: 'FormTemplate5',
    components: { ModalFilesManagementNodecode },
    mixins: [BpI18nHandlerMixin],
    props: {
        decodeData: Object,
        selectedCat: Object,
        variantAttribute: Boolean,
        onEditCatName: Function,
        categoryFieldNames: Object,
        lng: String
    },

    watch: {

    },

    data() {
        return {
            isAdd: false,
            rowId: false,
            areAllGoodsSelected: false
        };
    },

    created() {
        
    },

    methods: {
        onDataChanged(item, column) {
            this.$set(item, 'changed', true);
        },

        triggDownloadFile(filename) {
            this.$emit('triggDownloadFile', filename);
        },

        triggDownloadAllFiles(fileNamesList) {
            this.$emit('triggDownloadAllFiles', fileNamesList);
        },

        fileUpload(rowId) {
            this.$emit('fileUpload', rowId);
        },

        onNewFileAdded(files) {
            this.$refs.modalFilesManagement.setDataChanged(true);
            this.$refs.modalFilesManagement.addNewFiles(files);
        },
       
        keyPress: function (event, pattern) {
            const regex = new RegExp(pattern);
            const key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
            if (!regex.test(key)) {
                event.preventDefault();
                return false;
            }
        }, // вводит по заданному паттерну

        deleteItem(msg, row = false, index = false) {
            const goodsData = this.decodeData.goodsData;
            if (!this.variantAttribute || (!row && goodsData.findIndex(itm => itm.isItemToDelete) === -1)) return;
            let deletingRow = null;
            this.$bvModal.msgBoxConfirm(
                `${msg}...`,
                {
                    title: this.getCommonText('confirm'),
                    size: 'lg',
                    buttonSize: 'sm',
                    okVariant: 'danger',
                    okTitle: this.getCommonText('yes'),
                    cancelTitle: this.getCommonText('cancel'),
                    footerClass: 'p-2',
                    hideHeaderClose: false,
                    centered: true
                })
                .then(value => {
                    if (value) {
                        if (row) {
                            if (!row.id || row.id < 0) goodsData.splice(index, 1); 
                            else {
                                deletingRow = goodsData.splice(index, 1);
                                this.$emit('deleteDecodes', [row.id]);
                            }
                        } else {
                            const existingRowsToDel = [];
                            for (let i = goodsData.length - 1; i >=0; i--) {
                                if (goodsData[i].isItemToDelete) {
                                    const rowId = goodsData[i].id;
                                    if (rowId >= 0) {
                                        existingRowsToDel.push(rowId);
                                    };
                                    goodsData.splice(i, 1);
                                };
                            };
                            this.areAllGoodsSelected = false;
                            this.$emit('deleteDecodes', existingRowsToDel);
                        }
                        // this.checkDoubles();
                    }
                })
                .catch(error => {
                    // this.checkDoubles();
                    this.makeToast('danger', this.getErrText('on_del'), this.getErrText('decodes_not_del', { error: error.toString() }));
                });
        }, // удаление записи

        setIsAllDelete(val) {
            this.decodeData.goodsData.forEach(item => this.$set(item, 'isItemToDelete', val));
        },

        onFilesClick(item) {
            this.$refs.modalFilesManagement.showModal(item);
            this.rowId = item.id;
        }, // открытие модалки с файлами

        toggleIsAdd(return_object) {
            const curDecode = this.decodeData.goodsData.find(b => b.id === this.rowId);
            curDecode['row_files'] = return_object['row_files'];
            curDecode['files'] = return_object['num_attach_files'];
            this.$set(curDecode, 'changed', true);
            this.isAdd = return_object['isAdd'];
        },

        keyup13: function (event) {
            event.preventDefault();
            // Isolate the node that we're after
            const currentNode = event.target;
            // find all tab-able elements
            const allElements = document.querySelectorAll('input'); // area, object, select, [contenteditable]
            // Find the current tab index.
            const currentIndex = [...allElements].findIndex(el => currentNode.isEqualNode(el));
            // select/focus the following element
            const targetIndex = (currentIndex + 1) % allElements.length;
            if (targetIndex < allElements.length) {
                allElements[targetIndex].select();
            }
        }, // enter работает как tab

        roundNumber(number, decimalPlaces = 0) {
            const numberValue = Number(number) || 0;
            const decimalPlacesValue = Number(decimalPlaces) || 0;
            const rank = Math.pow(10, decimalPlacesValue);
            const mult = Math.round(numberValue * rank * 100) / 100;
            const roundedNumber = Math.round(mult) / rank;
            return roundedNumber;
        },

    },
    computed: {
        sums() {
            const sums = {
                sumPower: 0,
                sumTotal: 0
            };
            this.decodeData.goodsData.forEach(item => {
                sums.sumPower += item.power;
                sums.sumTotal += item.total;
            });
            return sums;
        }, 
        avgRate() {
            return this.decodeData.goodsData.length ? this.selectedCat.rate : 0;
        },
        totalRateNorm() {
            if (this.sums.sumPower === 0) return 0;
            return this.roundNumber(this.sums.sumTotal / this.sums.sumPower * 1000, 2);
        },
        totalTarif() {
            if (this.avgRate === 0) return 0;
            return this.roundNumber(this.totalRateNorm / this.avgRate, 2);
        },

        getFormText() {
            return this.setFormText('form_n_151.');
        },

        tableFields() {
            if (this.$i18n.locale) {
                return [
                    {
                        key: 'action',
                        label: ' '
                    },
                    {
                        key: 'name_object',
                        label: this.getCommonText('name')
                    },
                    {
                        key: 'rate',
                        label: this.getFormText('kind_norm')
                    },
                    {
                        key: 'tariff',
                        label: this.getFormText('rate')
                    },
                    {
                        key: 'rate_norm',
                        label: this.getFormText('monetary_norm')
                    },
                    {   
                        key: 'power',
                        label: this.getFormText('power_unit_count') 
                    },
                    {   
                        key: 'total',
                        label: this.getFormText('expense_sum')
                    },
                    {
                        key: 'files',
                        label: this.getCommonText('files')
                    },
                    {
                        key: 'more',
                        label: ''
                    },
                ];
            };
            return [];
        }
    }
}   
</script>

<style scoped>
    .form-03149-multiselect-group {
        display: flex;
    }

    .form-03149-multiselect-group .spinner-border {
        position: relative;
        right: 50px;
        opacity: 0.3;
        margin: auto;
        color: #7A87A0;
        z-index: 999;
    }

    .form-03149-spinner {
        display: block;
        margin: auto;
        color: #7A87A0;
    }

    .goods-form-val-msg {
        font-size: 0.5rem;
        font-style: italic;
        color: red;
    }

    .ens-item-non-active {
        color: #6087a099;
    }

    .goods-form-val-msg {
        font-size: 0.5rem;
        font-style: italic;
        color: red;
    }
    .textarea_padding_left{
        padding-left: 25px;
    }
    .textarea-min-width{
        min-width: 300px;
    }
    .bp-category-name {
        display: flex;
        justify-content: space-between;
    }

    .bp-category-name .icon-pencil-edit {
        align-self: center;
    }

</style>