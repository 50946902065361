//  ***************************************** //
//      Миксин для форм 01-151, 01-152, 01-153       //
//  ***************************************** //
export default {
    data() {
        return {
            decodeData: {
                categoryName: '',
                isDecodeTableOverlay: false,
                goodsData: [],
                hasDoubles: false
            },
            selectedCat: null,
            displayMode: 'category' //['category', 'decode']
        }
    },

    methods: {
        addGoodItem(nameRu, nameKk) {
            const newItem = {
                id: Date.now() * -1,
                category_id: this.selectedCat.category_id,
                name_object: nameRu,
                name_object_kk: nameKk,
                tariff: 0,
                power: 0,
                new_item: true,
                files: 0,
                row_files: [],
                changed: true
            };
            this.getExistedFiles(newItem);
            this.setFields(newItem);
            this.decodeData.goodsData.push(newItem);
        },

        async getExistedFiles(item) {
            try {
                const response = await fetch(`/api-py/get-new-row-files-form/${item.id}/${JSON.stringify(this.header)}`);
                const emptyRowFiles = await response.json();
                this.$set(item, 'row_files', emptyRowFiles);
            } catch (error) {
                this.makeToast('danger', this.getErrText('get_files'), error.toString());
            }
        },

        setFields(item) {
            this.$set(item, 'itemToDelete', false);
            this.$set(item, 'rate', this.selectedCat.rate);
            Object.defineProperty(item, 'rate_norm', {
                get: () => {
                    return this.roundNumber(item.rate * item.tariff, 2);
                }
            });
            Object.defineProperty(item, 'total', {
                get: () => {
                    return this.roundNumber(this.roundNumber(item.rate_norm * item.power, 2) / 1000, 3);
                }
            })
        },
        setCurrCat(item) {
            this.selectedCat = item;
            this.setCategoryName(item);
            this.setDisplayMode('decode');
            this.loadDecodeData(item);
        },
        setDisplayMode(mode) {
            const acceptableModes = ['category', 'decode'];
            if (!acceptableModes.includes(mode)) return;
            this.displayMode = mode;
        },
        setCategoryName(item) {
            if (item) {
                const objectName = item.comm_object ? item.comm_object[`name_${this.lang}`] : '';
                let serviceName = '';
                if (item.comm_service) {
                    serviceName = `${item.comm_service[`name_${this.lang}`]}.`;
                } else if (item.watering) {
                    serviceName = `${item.watering[`name_${this.lang}`]}.`;
                }
                this.decodeData.categoryName = `${serviceName} ${objectName}`
            } else this.decodeData.categoryName = '';
        },

        beforeLeaveDecode(isDataChanged) {
            if (isDataChanged) {
                this.onGoodsModalClose();
                return;
            };
            this.resetDecodeData();
        },

        onGoodsModalClose() {
            this.$bvModal.msgBoxConfirm(
                `${this.getCommonText('unsaved_changes')}!`,
                {
                    title: `${this.getCommonText('attention')}!`,
                    size: 'lg',
                    buttonSize: 'sm',
                    okVariant: 'danger',
                    okTitle: this.getCommonText('continue'),
                    cancelTitle: this.getCommonText('cancel'),
                    footerClass: 'p-2',
                    hideHeaderClose: false,
                    centered: true
                })
                .then(value => {
                    if (value) {
                        this.resetDecodeData();
                    }
                })
                .catch(error => {
                    this.makeToast('danger', this.getErrText('modal_close'), error.toString());
                });
        },

        resetDecodeData() {
            this.setDisplayMode('category');
            this.decodeData = {
                categoryName: '',
                isDecodeTableOverlay: false,
                goodsData: [],
                hasDoubles: false
            };
        },

        onDataChanged() {

        },

        prepareForSaveDecode(event) {
            event.preventDefault();
            if (!this.isAllDecodeFieldsFilled()) return;
            if (this.isHasDoubles()) return;
            const isCatMastBeSavedButNotValid = (this.selectedCat.id < 0 || this.selectedCat.changed) && !this.isCatDataValid(this.selectedCat)
            if (isCatMastBeSavedButNotValid) {
                this.makeToast('danger', this.getErrText('warning'),  `${this.getErrText('not_valid')}. ${this.getErrText('not_saved')}!`);
                return;
            }
            this.saveDecodes();
        },

        isAllDecodeFieldsFilled() {
            const goodsData = this.decodeData.goodsData;
            for (let i = 0; i < goodsData.length; i++) {
                const rowNmr = i + 1;
                if (!goodsData[i].name_object && !goodsData[i].name_object_kk) {
                    this.makeToast('warning', this.getCommonText('attention'), this.getFormText('name_empty', {rowNmr: rowNmr}));
                    return false;
                };
                if (!goodsData[i].tariff) {
                    this.makeToast('warning', this.getCommonText('attention'), this.getFormText('tariff_positiv', {rowNmr: rowNmr}));
                    return false;
                };
                if (!goodsData[i].power) {
                    this.makeToast('warning', this.getCommonText('attention'), this.getFormText('power_positiv', {rowNmr: rowNmr}));
                    return false;
                };
            } 
            return true;
        }, // проверка заполненности полей у расшифровках

        isHasDoubles() {
            if (this.decodeData.hasDoubles) {
                this.makeToast('warning', this.getCommonText('attention'), `${this.getErrText('cannot_uploadFile')}. ${this.getErrText('doubles')}.`);
                return true;
            }
            return false;
        },

        updateSavedRecords(newIds) {
            this.decodeData.goodsData.forEach(item => {
                const newId = newIds[item['id']];
                if (newId) {
                    this.$set(item, 'id', newId)
                    this.$set(item, 'changed', false)
                }
            })
        },

        updateSelectedCat(newCat) {
            this.$set(this.selectedCat, 'id', newCat.id);
            this.$set(this.selectedCat, 'tariff', newCat.tariff);
            this.$set(this.selectedCat, 'rate_norm', newCat.rate_norm);
            this.$set(this.selectedCat, 'power', newCat.power);
            this.$set(this.selectedCat, 'row_files', newCat.row_files);
            this.$set(this.selectedCat, 'files', newCat.files);
            this.$set(this.selectedCat, 'dec_total', newCat.dec_total);
            this.$set(this.selectedCat, 'decodes_id_with_files', newCat.decodes_id_with_files);
            this.$set(this.selectedCat, 'new_item', false);
        },

        // =======================================
        async loadDecodeData(item) {
            if (item.new_item) return;
            this.decodeData.isDecodeTableOverlay = true;
            this.decodeData.goodsData.splice(0);
            const bodyData = {
                header: this.header,
                params: item
            };
            try {
                const response = await fetch(
                    '/api-py/get-budget-request-form-with-row-attach-files-decode/', {
                        method: 'POST',
                        headers: {
                        'Content-Type': 'application/json;charset=utf-8'
                        },
                    body: JSON.stringify(bodyData)
                });
                let values = [];
                if (response.status === 200) {
                    values = await response.json();
                    values.forEach(val => {
                        this.setFields(val);
                    })
                    this.decodeData.goodsData = values;
                };
                this.decodeData.goodsData = values;
                this.decodeData.isDecodeTableOverlay = false;
            } catch (error) {
                this.decodeData.isDecodeTableOverlay = false;
                this.decodeData.goodsData.splice(0);
                this.makeToast('danger', this.getErrText('decodes_upld'), error.toString());
                return;
            };
        },

        async saveDecodes() {
            const dataToSave = {
                values: this.decodeData.goodsData.filter(itm => itm.changed === true),
                header: this.header,
                curCat: this.selectedCat
            };
            this.decodeData.isDecodeTableOverlay = true;
            try {
                const response = await fetch('/api-py/save-brform-spf151-decode/', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json;charset=utf-8'
                    },
                    body: JSON.stringify(dataToSave)
                });
                if (response.status === 200) {
                    const result = await response.json();
                    this.updateSavedRecords(result.saved_decodes);
                    if (result.saved_cat.length) this.updateSelectedCat(result.saved_cat[0]);
                    this.makeToast('success', this.getErrText('msg'), this.getErrText('saved'));
                    this.deletingAgreementTotalResultHandler(result);
                } else {
                    this.makeToast('danger', this.getErrText('warning'), `${this.getErrText('saving_error')} ${response.status}`);
                }
                this.decodeData.isDecodeTableOverlay = false;
            } catch (e) {
                this.decodeData.isDecodeTableOverlay = false;
                this.makeToast('danger', this.getErrText('warning'), `${this.getErrText('saving_error')} ${e.toString()}`);
            } 
        },
         
        async deleteDecodes(idsToDel) {
            if (idsToDel.length === 0) return;
            this.decodeData.isDecodeTableOverlay = true;
            const dataToDelete = {
                idsToDel,
                header: this.header,
                curCat: this.selectedCat
            };
            try {
                const response = await fetch('/api-py/delete-brform-spf151-decode/', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json;charset=utf-8'
                    },
                    body: JSON.stringify(dataToDelete)
                });
                if (response.status === 200) {
                    const result = await response.json();
                    if (result.deleted_cat.length) this.updateSelectedCat(result.deleted_cat[0]);
                    this.makeToast('success', this.getErrText('msg'), this.getErrText('deleted'));
                    this.deletingAgreementTotalResultHandler(result);
                } else {
                    this.makeToast('danger', this.getErrText('on_del'), this.getErrText('decodes_not_del', { error: response.status }));
                }
                this.decodeData.isDecodeTableOverlay = false;
            } catch (e) {
                this.decodeData.isDecodeTableOverlay = false;
                this.makeToast('danger', this.getErrText('warning'), this.getErrText('decodes_not_del', { error: e.toString() }));
            } 
        },

        roundNumber(number, decimalPlaces) {
            const numberAsFloat = typeof number === 'string' ? parseFloat(number) : number;
            
            if (!isNaN(numberAsFloat) && typeof numberAsFloat === 'number') {
                const multiplier = Math.pow(10, decimalPlaces);
                return Math.round(numberAsFloat * multiplier) / multiplier;
            };
            return 0;
        },

        onCatNameChanged(row) {
            this.$set(row, 'changed', true);
        },
        
        deletingAgreementTotalResultHandler(result) {
            if ('agreement_deleting_result' in result) {
                const res = result.agreement_deleting_result;
                console.log('Service budget_request_total_agreement_delete has complited');
                console.log(`Result: ${res.result}`);
                if ('delete' in res) {
                    console.log(`Agreement total propably was deleted: ${res.delete}`);
                }
                if ('error' in res) {
                    console.log(`Error message: ${res.error}`);
                }
                console.log(`_______________________________`);
                console.log(``);

            }
            if ('agreement_deleting_result' in result && result.agreement_deleting_result.result === 'error') {
                const res = result.agreement_deleting_result;
                const errorDescr = 'error' in res ? res.error : '';
                this.makeToast('danger', 'budget-request-total-agreement-delete', `Ошибка ${errorDescr}`);
            }            
        },
    },

    computed: {
        lang() {
            return this.$i18n.locale === 'kk' ? 'kk' : 'ru';
        },

        isCategoryMode() {
            return this.displayMode === 'category';
        },

        isDecodeMode() {
            return this.displayMode === 'decode';
        }
    }
}