<template>
    <b-modal id="files-modal"
                ref="fileManagement"
                hide-footer
                size="md"
                centered
                scrollable
                modal-class="modal-table modal-files multiselect-modal"
    >
        <template #modal-header="{ close }" class="modal-header">
            <div class="title">{{`${getFmText('files')} (${currItemName})`}}</div>
            <i class="icon icon-close" @click="close()"></i>
        </template>
        
        <div class="modal-search">
            <b-form-group>
                <!-- Поле ввода текста для поиска -->
                <b-form-input ref="searchTextField" v-model="searchText" :placeholder="getFmText('search')" type="text"/>
                <!-- Кнопка очистки текста -->
                <i class="icon icon-close clear-button" @click="() => { searchText = '' }"/>
            </b-form-group>  
        </div>
        
        <div class="table-container modal-table-card mb-3">
            <p class="file-not-found" v-show="!searchedFiles.length">{{ getFmText('not_found') }}</p>

            <div class="file-info" v-for="item in searchedFiles" :key="item.id">
                <div :key="item.id" class="d-flex align-items-center">
                    <template >
                        <p  class="name">
                            <file-icons :filename="item.file_name"></file-icons>
                            <span style="cursor: pointer" @click="()=>$emit('triggDownloadFile', item.file_name)">{{item.file_name}}</span>
                        </p>
                        <p class="size">{{item['size']}}</p>
                        <p class="size">({{getCatName(item.category)}})</p>
                    </template>
                </div>
            </div>
        </div>
        
        <b-button
                size="sm"
                variant="success"
                class="download"
                :disabled="attachedFiles.length === 0"
                @click="$emit('triggDownloadAllFiles', attachedFiles)"
            >
                {{ getFmText('download_all') }}
        </b-button>
    </b-modal>
</template>

<script>
import FileUpdownMixin from '../mixins/file-updown-mixin';
import FileIcons from './file-icons.vue';

export default {
    name: "modal-all-files-management",
    components: { FileIcons },
    props: {
        allFiles: Array,
        hasFilesTable: {
            required: false,
            default: false
        }
    },
    mixins: [FileUpdownMixin],
    data() {
        return {
            selectedCategory: 'All',
            searchText: '',
            currItem: null,
            newFileCodes: null,
            dataChanged: false,
        }
    },
    methods: {
        async showModal(item) {  
            this.searchText = '';          
            this.currItem = item;

            this.newFileCodes = item.files;
            await this.loadDict();
            this.$refs.fileManagement.show();
        },

        getCatName(code) {
            const currCat = this.categoryFiles.find(item => item.value === code);
            return currCat ? currCat.name : null
        },
        
        stringToUnicode(string) {
            let chr = '';
            if (string.length === 0) return hash;
            for (let i = 0; i < string.length; i++) {
                chr += string.charCodeAt(i); 
            }
            return chr;
        },

       getFmText(field, extra = null) {
            const link = 'modules.budget_request.files_management.';
            return this.getText(link, field, extra)
        },
    },
    computed: {
        attachedFiles() {
            let newArr = [];
            if (this.hasFilesTable) {
                newArr = this.allFiles;
            } else {
                this.allFiles.forEach(item => {
                    if (this.currItem && this.currItem.files && this.currItem.files.includes(this.stringToUnicode(item.category + item.file_name))) {
                        this.$set(item, 'row_id', item.id);
                        newArr.push(item);
                    }
                });
            };
            return newArr
        },
        searchedFiles() {
            if (!this.attachedFiles) return [];
            let sortedFiles = this.attachedFiles;
            sortedFiles = sortedFiles.filter(item => {
                let result = true;
                if (this.searchText.length) {
                    result = result && item.file_name.toLowerCase().includes(this.searchText.toLowerCase());
                };
                result = result && item.row_id;
                return result;
            });
            return sortedFiles.sort((a, b) => a.file_name > b.file_name ? 1 : -1);
        },

        currItemName() {
            let curName = '';
            const name = `name_${this.lng}`;
            if (this.currItem) {
                if (this.currItem[name]) curName = this.currItem[name]
                else curName = this.currItem.name_ru;
            }
            return curName
        }
    },
}

</script>

<style scoped>
.file-cat-name {
    font-size: 0.9rem;
}
.file-not-found {
    font-size: 0.8rem;
    font-style: italic;
    color: #A8B5CD;
    margin-left: 2rem;
}
#files-modal .icon-close {
    cursor: pointer;
}
.modal-header {
    display: flex;
    justify-content: space-between;
}
.modal-header div {
    flex-grow: 1;
    text-align: center;
}
.download {
    display: block;
    margin: auto;
}
.modal-search {
    margin-top: 15px;
}
</style>