






































import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({
    name: 'modal-note',
    
})

export default class ModalNote extends Vue {
    @Prop({
        required: true,
    })
    private noteFieldName!: any;

    @Prop({
        required: false,
    })
    private getCommonText!: any;

    private isNoteChanged = false;

    private note = '';

    private get noteField() {
        return this.noteFieldName;
    }

    private get okBtnName() {
        return 'OK';
    }
    
    private isNoteModalShown = false;

    private isEditMode = false;

    
    private curRowId = null;
    private curRow: {[key: string]: string} = {};


    private showModal(row?: any) {
        this.setModalParams(row);
        this.isNoteModalShown = true;
    }

    private setModalParams(row?: any) {
        if (row) {
            this.curRowId = row.id;
            this.curRow = row;
        }
        this.isEditMode = !!row;
        this.setNote(row);
    }

    private setNote(row: any) {
        const field_note = this.noteField;
        this.note = field_note && row[field_note] ? row[field_note] : '';
    }
    
    private hideModal() {
        this.isNoteModalShown = false;
    }

    private resetForm() {
        this.note = '';
        this.resetNoteChanged();
        this.curRowId = null;
        this.curRow = {};
    }

    private maxNameLengthSymbls = 255;
    private onInput(value: any) {
        const valueWithoutNewlines = value.replace(/\n/g, '');

        const cuttedValue = valueWithoutNewlines.slice(0, this.maxNameLengthSymbls);

        this.$nextTick(() => {
            this.note = cuttedValue;
        });
    }

    private resetNoteChanged() {
        this.isNoteChanged = false;
    }
    
    private onChangeNote() {
        const isNoteEmpty = !this.note.trim();

        if (isNoteEmpty) this.resetNoteChanged()
        else this.isNoteChanged = true;
    }

    private onOkBtnClicked() {
        const isFormEmpty = !this.note.trim();
        if (isFormEmpty) this.hideModal();

        if (this.isEditMode && this.curRow) {
            this.$set(this.curRow, this.noteField, this.note);
        }
        this.$emit('onNoteChanged', this.curRow);
        this.hideModal();
    }

}
